import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import RouteContainer from "./RouteContainter";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <RouteContainer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { MenuButton, TextInput } from "../../components/input/inputs";
import Modal from "../../components/modal/modal";
import styles from "../../styles/authorizations.module.css";
import userStyles from "../users/styles/list.module.css";
import main from '../../styles/main.module.css';
import axiosInstance from "../../AxiosInstance";
import { NATIONALITY_OPTIONS } from "./data/nationalities";

const INITIAL_FORM = {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    cod_nacionalidad: "",
    documento_identidad: "",
    numero_telefono: "",
    rol: [], 
    photo: null,
    id: null,
}

export default function PeopleList() {
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [form, setForm] = useState(INITIAL_FORM);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });
    const [itemMenuOpen, setItemMenuOpen] = useState({
        idUser: null,
    });
    const [errorMessages, setErrorMessages] = useState({});
    const [generalError, setGeneralError] = useState(""); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userList = await getUserList();
                setUsers(userList);
                setCheckedList(userList.map(() => false));

                const groupOptions = await getGroupOptions();
                setGroups(groupOptions);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const getUserList = () => {
        return axiosInstance.get('users/').then(response => response.data);
    };

    const getGroupOptions = async () => {
        try {
            const response = await axiosInstance.get('groups/');
            return response.data.map(group => ({
                value: group.id,
                label: group.name
            }));
        } catch (error) {
            console.error("Error fetching group options:", error);
            return [];
        }
    };

    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
    };

    const handleAllChecked = (event) => {
        const { checked } = event.target;
        setCheckedList(users.map(() => checked));
        setAllChecked(checked);
    }

    const handleCheckedIndex = (event, index) => {
        const { checked } = event.target;
        let newCheckedList = [...checkedList];
        newCheckedList[index] = checked;
        setCheckedList(newCheckedList);
        setAllChecked(newCheckedList.every(val => val));
    }

    const validateForm = () => {
        let errors = {};
        if (!form.username) errors.username = "El nombre de usuario es obligatorio.";
        if (!form.email) errors.email = "El correo electrónico es obligatorio.";
        if (!form.first_name) errors.first_name = "El nombre es obligatorio.";
        if (!form.last_name) errors.last_name = "El apellido es obligatorio.";
        if (!form.cod_nacionalidad) errors.cod_nacionalidad = "La nacionalidad es obligatoria.";
        if (!form.documento_identidad) errors.documento_identidad = "El documento de identidad es obligatorio.";
        if (!form.numero_telefono) errors.numero_telefono = "El número de teléfono es obligatorio.";
        if (form.rol.length === 0) errors.rol = "El rol es obligatorio."; 

        return errors;
    }

    const handleForm = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            setGeneralError("Por favor, complete todos los campos obligatorios.");
            return;
        }

        const { id, photo, rol, ...rest } = form;
        const formData = new FormData();
        for (const key in rest) {
            formData.append(key, rest[key]);
        }
        if (photo) {
            formData.append('photo', photo);
        }
        if (rol.length > 0) {
            rol.forEach(groupId => formData.append('groups', groupId));
        }

        const url = id ? `users/${id}/` : 'users/';
        const method = id ? 'patch' : 'post';

        try {
            await axiosInstance[method](url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const userList = await getUserList();
            setUsers(userList);
            setShowCreateModal(false);
            resetForm();
            setErrorMessages({});
            setGeneralError(""); // Clear general error message
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setErrorMessages(error.response.data);
            } else {
                console.error("Error handling form submission:", error);
            }
        }
    }

    const handleCancelCreate = () => {
        resetForm();
    }

    const handleItemMenu = (id) => {
        setItemMenuOpen({
            idUser: itemMenuOpen.idUser === id ? null : id,
        });
    }

    const handleEditUser = (user) => {
        setShowCreateModal(true);
        setForm({
            username: user.username,
            cod_nacionalidad: user.cod_nacionalidad,
            documento_identidad: user.documento_identidad,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            numero_telefono: user.numero_telefono,
            password: '',
            rol: user.groups.map(group => group.id), 
            id: user.id,
        });
    }

    const handleDeleteUser = (user) => {
        setShowDeleteModal(true);
        setForm({
            username: user.username,
            cod_nacionalidad: user.cod_nacionalidad,
            documento_identidad: user.documento_identidad,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            numero_telefono: user.numero_telefono,
            password: '',
            rol: user.groups.map(group => group.id), 
            id: user.id,
        });
    }

    const handleConfirmDelete = async () => {
        const { id } = form;
        try {
            await axiosInstance.delete(`users/${id}/`);
            const userList = await getUserList();
            setUsers(userList);
            setShowDeleteModal(false);
            resetForm();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    }

    const resetForm = () => {
        setForm(INITIAL_FORM);
        setErrorMessages({});
        setGeneralError(""); // Clear general error message
    }

    return (
        <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>
            <Header current={1} onSidebarToggle={handleSidebarToggle} />
            <Modal show={showCreateModal} setter={setShowCreateModal} name={`${form.id ? 'Editar' : 'Agregar'} Usuario`} buttonName={form.id ? 'Guardar' : 'Agregar'} onClickCancel={handleCancelCreate} onClick={handleForm}>
                <div className="container-fluid">
                    {generalError && <div className="alert alert-danger">{generalError}</div>}
                    <div className="form-group">
                        <TextInput name={'Usuario'} form={form} setForm={setForm} internalName={'username'} errorMessage={errorMessages.username} />
                    </div>
                    <div className="form-group">
                        <TextInput 
                            name={'Nacionalidad'} 
                            form={form} 
                            setForm={setForm} 
                            internalName={'cod_nacionalidad'}
                            type={'select'}
                            options={NATIONALITY_OPTIONS}
                            placeholder={'Selecciona una opción'}
                            errorMessage={errorMessages.cod_nacionalidad}
                        />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Documento de Identidad'} form={form} setForm={setForm} internalName={'documento_identidad'} errorMessage={errorMessages.documento_identidad} />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Correo'} form={form} setForm={setForm} internalName={'email'} errorMessage={errorMessages.email} />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Nombre'} form={form} setForm={setForm} internalName={'first_name'} errorMessage={errorMessages.first_name} />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Apellido'} form={form} setForm={setForm} internalName={'last_name'} errorMessage={errorMessages.last_name} />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Número de Teléfono'} form={form} setForm={setForm} internalName={'numero_telefono'} errorMessage={errorMessages.numero_telefono} />
                    </div>
                    <div className="form-group">
                        <TextInput 
                            password={true} 
                            name={'Contraseña'} 
                            form={form} 
                            setForm={setForm} 
                            internalName={'password'} 
                            placeholder={'Dejar en blanco para mantener la contraseña actual'}
                        />
                    </div>
                    <div className="form-group">
                        <TextInput 
                            name={'Rol'} 
                            form={form} 
                            setForm={setForm} 
                            internalName={'rol'}
                            type={'select'}
                            options={groups}
                            placeholder={'Selecciona una opción'}
                            multiple
                            errorMessage={errorMessages.rol}
                        />
                    </div>
                    <div className="form-group">
                        <TextInput name={'Foto'} form={form} setForm={setForm} internalName={'photo'} type={'file'} />
                    </div>
                </div>
            </Modal>

            <Modal show={showDeleteModal} setter={setShowDeleteModal} name={'Eliminar Usuario'} buttonName={'Eliminar'} onClickCancel={handleCancelCreate} onClick={handleConfirmDelete}>
                <div>
                    <div className={"d-flex flex-row align-items-center w-100"}>
                        {`¿Está seguro que desea eliminar al usuario ${form.first_name} ${form.last_name}?`}
                    </div>
                </div>
            </Modal>
            <div className={'bg-light vh-100'}>
                <div className={`${styles.createContainer}`}>
                    <div className={'d-flex align-items-center'} style={{ justifyContent: "space-between", width: "100%" }}>
                        <div className={`${styles.listTitle}`}>Lista de Usuarios</div>
                        <div className="d-flex align-items-center">
                            <div>
                                <button className={`btn ${main.mainButton}`} onClick={() => setShowCreateModal(true)}>
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <table className={`${styles.listTable} mt-3`}>
                    <thead>
                        <tr>
                            <th className={`${styles.tableItemHeader}`}></th>
                            <th className={`${styles.tableItemHeader}`}>Nombre</th>
                            <th className={`${styles.tableItemHeader}`}>Documento de Identidad</th>
                            <th className={`${styles.tableItemHeader}`}>Correo</th>
                            <th className={`${styles.tableItemHeader}`}>N° Teléfono</th>
                            <th className={`${styles.tableItemHeader}`}>Rol</th>
                            <th className={`${styles.tableItemHeader}`}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>
                                    {user.photo && <img src={user.photo} alt="User Photo" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />}
                                </td>
                                <td className={`${styles.tableItem}`}>{`${user.first_name} ${user.last_name}`}</td>
                                <td className={`${styles.tableItem}`}>{user.documento_identidad}</td>
                                <td className={`${styles.tableItem}`}>{user.email}</td>
                                <td className={`${styles.tableItem}`}>{user.numero_telefono}</td>
                                <td className={`${styles.tableItem}`}>{user.groups.map(group => group.name).join(', ')}</td>
                                <td>
                                    <MenuButton onClick={() => handleItemMenu(user.id)}>
                                        {itemMenuOpen.idUser === user.id && (
                                            <div className={`${userStyles.dropdown}`}>
                                                <div onClick={() => handleEditUser(user)} className={`${userStyles.dropdownItem}`}>Editar</div>
                                                <div onClick={() => handleDeleteUser(user)} className={`${userStyles.dropdownItem}`}>Eliminar</div>
                                            </div>
                                        )}
                                    </MenuButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

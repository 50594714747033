import Header from "../../components/header";
import React, { useEffect, useState } from "react";


export default function Home() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });
    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
    };


    return (
        <div style={{height: '100% !important', marginLeft: '250px'}}>
            <Header onSidebarToggle={handleSidebarToggle} />


            <div className={'bg-light vh-100 px-5 py-3'} style={{marginTop: '120px'}}>
                <img src={'/main.png'} style={{width: '80%'}}/>
            </div>
        </div>
    )
}
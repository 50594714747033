import React, {useEffect, useState} from "react";
import axios from "../../AxiosInstance";
import styles from './styles/modal.module.css';

export default function LoadingModal() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            window.location.replace('/modal/error', {state: "fullscreen"});
        }, 30000)

        const fetchProcessingStatus =  () => {
            axios.get('sistema/').then((response) => {
                const data = response.data.results[0];
                if (data.processing_status === 2) {
                    window.location.replace('/modal/authorized', {state: "fullscreen"});
                } else if (data.processing_status === 3) {
                    window.location.replace('/modal/unauthorized', {state: "fullscreen"});
                }
                else if (data.processing_status === 4) {
                    window.location.replace('/modal/error', {state: "fullscreen"});
                }
            })
        }

        const interval = setInterval(() => {
            fetchProcessingStatus()
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.loadingSpinner}>
            <div className={styles.spinner}></div>
            <div className={styles.text}>CARGANDO</div>
        </div>
    );
}
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from '../../AxiosInstance';
import Header from "../../components/header";
import styles from "../../styles/crud.module.css";

export default function CreateSchedule() {
    const location = useLocation()

    const [form, setForm] = useState({ type: 1, day: 1 });
    const [error, setError] = useState(false);

    const setField = (name, value) => {
        setForm({
            ...form,
            [name]: value
        });
    }

    const submit = () => {
        if (location.state) {
            axios.put('forced_open/', form).then((response) => {
                window.location.replace('/schedule/list');
            }).catch((err) => {
                setError(true);
            })
        } else {
            axios.post('forced_open/', form).then((response) => {
                window.location.replace('/schedule/list');
            }).catch((err) => {
                setError(true);
            })
        }
    }

    return (
        <div>
            <Header />

            <div className="container-fluid bg-light vh-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-6 col-lg-3 rounded p-4 bg-white shadow" style={{ minWidth: '450px' }}>
                        <div className={styles.crudTitle}>Barrera</div>
                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Día</div>
                            <select className={`${styles.borderDarkBlue} w-100`} value={form.day} onChange={(event) => setField("day", event.target.value)}>
                                <option value={'1'}>Lunes</option>
                                <option value={'2'}>Martes</option>
                                <option value={'3'}>Miércoles</option>
                                <option value={'4'}>Jueves</option>
                                <option value={'5'}>Viernes</option>
                                <option value={'6'}>Sábado</option>
                                <option value={'7'}>Domingo</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Hora</div>
                            <input type='number' className={`form-control ${styles.borderDarkBlue} rounded-2`} style={{ borderWidth: '2px' }} id="hour" value={form.hour} onChange={(event) => setField("hour", event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Minuto</div>
                            <input type='number' className={`form-control ${styles.borderDarkBlue} rounded-2`} style={{ borderWidth: '2px' }} id="minute" value={form.minute} onChange={(event) => setField("minute", event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Operación</div>
                            <select className={`${styles.borderDarkBlue} w-100`} value={form.type} onChange={(event) => setField('type', event.target.value)}>
                                <option value={'1'}>Abrir Barrera</option>
                                <option value={'0'}>Cerrar Barrera</option>
                            </select>
                        </div>

                        {error && <div className={styles.errorLabel}>Por favor revise todos los campos</div>}

                        <Button className={`mt-3 btn text-white rounded-2 px-4 ${styles.submitButton}`} onClick={submit}>{location.state ? "Guardar" : "Crear"}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import Header from "../../components/header";
import styles from '../../styles/authorizations.module.css';
import axios from '../../AxiosInstance';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faTrash} from "@fortawesome/free-solid-svg-icons";
import main from "../../styles/main.module.css";
import PaginationComponent from "../../components/pagination";

export default function ScheduleList() {
    const [tasks, setTasks] = useState([])

    const url = "tranquera_schedule/?";
    const defaultUrl= "tranquera_schedule/?";

    const pullData = (data) => {
        const filteredResults = data.filter(result => {
            const task = result.task;
            return task.includes('api_arduino.tasks.forced_open_per_date') || task.includes('api_arduino.tasks.forced_close_per_date');
        });
        setTasks(filteredResults);
    }


    useEffect(() => {
        axios.get('tranquera_schedule/').then((response) => {
            const filteredResults = response.data.results.filter(result => {
                const task = result.task;
                return task.includes('api_arduino.tasks.forced_open_per_date') || task.includes('api_arduino.tasks.forced_close_per_date');
            });
            setTasks(filteredResults);
        });
    }, []);

    const readableDate = (number) => {
        const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

        if (number >= 1 && number <= 7) {
            return daysOfWeek[number - 1];
        } else {
            return 'Invalid day number';
        }
    }

    const deleteCron = (cron_id) => {
        axios.delete('tranquera_schedule/' + cron_id + '/').then((response) => {
            window.location.reload();
        });
    }

    return (
        <div style={{height: '100% !important', marginLeft: '250px'}}>
            <Header/>

            <div className={'bg-light vh-100 px-5 py-3'} style={{marginTop: '120px'}}>
                <div className={`${styles.createContainer }`}>
                    <div className={`${styles.listTitle}`}>Eventos</div>
                    <Link className={`btn ${main.secondaryButton}`} to="/schedule/create">
                        <div className={main.secondaryButtonItem2}>
                            <FontAwesomeIcon icon={faCalendar}/>
                            <div>Programar</div>
                        </div>
                    </Link>
                </div>

                <table className={`${styles.listTable} mt-3`}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th className={`${styles.tableItemHeader}`}>Tarea</th>
                        <th className={`${styles.tableItemHeader}`}>Día</th>
                        <th className={`${styles.tableItemHeader}`}>Hora</th>
                        <th className={`${styles.tableItemHeader}`}>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tasks.map(task => (
                        <tr key={task.id}>
                            <td className={`${styles.tableItem}`}>{task.id}</td>
                            <td className={`${styles.tableItem}`}>{task.task === 'api_arduino.tasks.forced_open_per_date' ? "Abrir" : "Cerrar"}</td>
                            <td className={`${styles.tableItem}`}>{readableDate(task.crontab.day_of_week)}</td>
                            <td className={`${styles.tableItem}`}>{task.crontab.hour}:{task.crontab.minute}</td>
                            <td className={`${styles.tableItem}`}>
                                <div className="d-flex flex-row">
                                    <div role="button" onClick={() => {deleteCron(task.id)}}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <PaginationComponent prop={url} defaultUrl={defaultUrl} func={pullData} limit={5}/>
            </div>
        </div>
    )
}
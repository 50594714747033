import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import styles from '../../styles/authorization_modals.module.css'

export default function AuthorizedModal() {
    useEffect(() => {
        setTimeout(() => {
            window.location.replace('/modal/welcome', {state: "fullscreen"});
        }, 10000);
    }, []);

    return(
        <div className={`${styles.background_green} h-100`}>
            <div className={styles.container}>
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faCheck} color="#FFF" size="3x"/>
                </div>
                <div className={styles.text}>AUTORIZADO</div>
            </div>
        </div>
    )
}
import { createContext, useContext, useState } from "react";

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const [authenticated, setAuthenticated] = useState(localStorage.getItem('access_token') !== null)

    const login = (response) => {
        localStorage.clear();
        localStorage.setItem('access_token', response.data.token);
        // localStorage.setItem('refresh_token', response.data.refresh);
        // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        setAuthenticated(true)
    }

    const logout = () => {
        localStorage.clear();
        setAuthenticated(false)
    }

    // const verifyAuthStatus = () => {
    //Llamado a una api y verificar si el usuario esta autenticado
    // Endpoint de verificacion del token => true o false

    //If false => /login
    // }

    return (
        <AuthContext.Provider value={{ authenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
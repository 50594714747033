import React, { useEffect, useRef } from 'react';
import styles from './modalcomponent.module.css';

export default function ModalComponent({ message, imgData, duration, onClose, showModal }) {
    const photoRef = useRef(null)
    const noPhotoRef = useRef(null)
    const containerRef = useRef(null)
    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (showModal) {
            containerRef.current.addEventListener('click', handleOutsideClick);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('click', handleOutsideClick);
            }
        };
    }, [showModal]);

    const handleOutsideClick = (event) => {
        if (
            (photoRef.current &&
                !photoRef.current.contains(event.target)) ||
            (noPhotoRef.current &&
                !noPhotoRef.current.contains(event.target))
        ) {
            handleClose()
            // console.log('click Afuera')
        }
    };

    // const [visible, setVisible] = useState(showModal);

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setShowModal(false);
    //         onClose();
    //     }, duration);

    //     return () => clearTimeout(timeout);
    // }, [duration, onClose]);

    const handleClose = () => {
        // setShowModal(false)
        onClose();
        console.log('cerrando')
        // clearTimeout();
    };

    return (
        <>
            {showModal && (
                <div ref={containerRef} className={styles.modal}>
                    <div className={styles.modalContent}  >
                        {imgData !== "" ?
                            <img className={styles.image} src={imgData} alt={'plate'} ref={photoRef} />
                            :
                            <div className={styles.modalText} ref={noPhotoRef}>{message}</div>
                        }
                        {/* <div className={styles.closeButton} role='button' onClick={handleClose}>
                            <div>X</div>
                        </div> */}
                    </div>
                </div>
            )}
        </>
    );
};

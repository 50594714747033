import React, {useEffect} from "react";
import styles from './styles/modal.module.css';
import axios from "../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand} from "@fortawesome/free-solid-svg-icons";

export default function WelcomeModal() {

    useEffect(() => {
        const fetchProcessingStatus =  () => {
            axios.get('sistema/').then((response) => {
                const data = response.data.results[0];
                if (data.processing_status === 1) {
                    window.location.replace('/modal/loading', {state: "fullscreen"});
                }
            })
        }

        const interval = setInterval(() => {
            fetchProcessingStatus()
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const setFullscreen = () => {
        const docElement = document.documentElement;

        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            // Enter fullscreen mode
            if (docElement.requestFullscreen) {
                docElement.requestFullscreen();
            } else if (docElement.mozRequestFullScreen) { // Firefox
                docElement.mozRequestFullScreen();
            } else if (docElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                docElement.webkitRequestFullscreen();
            } else if (docElement.msRequestFullscreen) { // IE/Edge
                docElement.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen mode
            if (document.exitFullscreen) {
                document.exitFullscreen()
                    .catch(error => {
                        console.log('Error attempting to exit fullscreen:', error);
                    });
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    }

    return (
        <div>
            <div className={styles.absButton} role="button" onClick={setFullscreen}>
                <FontAwesomeIcon icon={faExpand} size="xl"/>
            </div>
            <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '90vh' }}>
                <div className={`${styles.welcomeText} ${styles.fontBig}`}>¡Gracias!</div>
                <div className={`${styles.welcomeText} fs-2`}>Estamos agradecidos</div>
                <div className={`${styles.welcomeText} fs-2`}>por tu visita</div>
            </div>
            <img src={'/fondo.png'} alt='mine' className={styles.bgimg}/>
        </div>
    )
}

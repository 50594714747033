const NATIONALITY_OPTIONS = [
    { value: "AFG", label: "Afganistán" },
    { value: "ALB", label: "Albania" },
    { value: "DEU", label: "Alemania" },
    { value: "AND", label: "Andorra" },
    { value: "AGO", label: "Angola" },
    { value: "AIA", label: "Anguila" },
    { value: "ATA", label: "Antártida" },
    { value: "ATG", label: "Antigua y Barbuda" },
    { value: "SAU", label: "Arabia Saudita" },
    { value: "DZA", label: "Argelia" },
    { value: "ARG", label: "Argentina" },
    { value: "ARM", label: "Armenia" },
    { value: "ABW", label: "Aruba" },
    { value: "AUS", label: "Australia" },
    { value: "AUT", label: "Austria" },
    { value: "AZE", label: "Azerbaiyán" },
    { value: "BHS", label: "Bahamas" },
    { value: "BGD", label: "Bangladés" },
    { value: "BRB", label: "Barbados" },
    { value: "BHR", label: "Baréin" },
    { value: "BEL", label: "Bélgica" },
    { value: "BLZ", label: "Belice" },
    { value: "BEN", label: "Benín" },
    { value: "BMU", label: "Bermudas" },
    { value: "BLR", label: "Bielorrusia" },
    { value: "MMR", label: "Myanmar" },
    { value: "BOL", label: "Bolivia" },
    { value: "BIH", label: "Bosnia y Herzegovina" },
    { value: "BWA", label: "Botsuana" },
    { value: "BRA", label: "Brasil" },
    { value: "BRN", label: "Brunéi Darussalam" },
    { value: "BGR", label: "Bulgaria" },
    { value: "BFA", label: "Burkina Faso" },
    { value: "BDI", label: "Burundi" },
    { value: "BTN", label: "Bután" },
    { value: "CPV", label: "Cabo Verde" },
    { value: "KHM", label: "Camboya" },
    { value: "CMR", label: "Camerún" },
    { value: "CAN", label: "Canadá" },
    { value: "QAT", label: "Catar" },
    { value: "BES", label: "Bonaire" },
    { value: "TCD", label: "Chad" },
    { value: "CHL", label: "Chile" },
    { value: "CHN", label: "China" },
    { value: "CYP", label: "Chipre" },
    { value: "COL", label: "Colombia" },
    { value: "COM", label: "Comoras" },
    { value: "KOR", label: "Corea" },
    { value: "CIV", label: "Costa de Marfil" },
    { value: "CRI", label: "Costa Rica" },
    { value: "HRV", label: "Croacia" },
    { value: "CUB", label: "Cuba" },
    { value: "CUW", label: "Curaçao" },
    { value: "DNK", label: "Dinamarca" },
    { value: "DMA", label: "Dominica" },
    { value: "ECU", label: "Ecuador" },
    { value: "EGY", label: "Egipto" },
    { value: "SLV", label: "El Salvador" },
    { value: "ARE", label: "Emiratos Árabes Unidos" },
    { value: "ERI", label: "Eritrea" },
    { value: "SVK", label: "Eslovaquia" },
    { value: "SVN", label: "Eslovenia" },
    { value: "ESP", label: "España" },
    { value: "USA", label: "Estados Unidos" },
    { value: "EST", label: "Estonia" },
    { value: "ETH", label: "Etiopía" },
    { value: "PHL", label: "Filipinas" },
    { value: "FIN", label: "Finlandia" },
    { value: "FJI", label: "Fiyi" },
    { value: "FRA", label: "Francia" },
    { value: "GAB", label: "Gabón" },
    { value: "GMB", label: "Gambia" },
    { value: "GEO", label: "Georgia" },
    { value: "GHA", label: "Ghana" },
    { value: "GIB", label: "Gibraltar" },
    { value: "GRD", label: "Granada" },
    { value: "GRC", label: "Grecia" },
    { value: "GRL", label: "Groenlandia" },
    { value: "GLP", label: "Guadalupe" },
    { value: "GUM", label: "Guam" },
    { value: "GTM", label: "Guatemala" },
    { value: "GUF", label: "Guayana Francesa" },
    { value: "GGY", label: "Guernsey" },
    { value: "GIN", label: "Guinea" },
    { value: "GNB", label: "Guinea-Bisáu" },
    { value: "GNQ", label: "Guinea Ecuatorial" },
    { value: "GUY", label: "Guyana" },
    { value: "HTI", label: "Haití" },
    { value: "HND", label: "Honduras" },
    { value: "HKG", label: "Hong Kong" },
    { value: "HUN", label: "Hungría" },
    { value: "IND", label: "India" },
    { value: "IDN", label: "Indonesia" },
    { value: "IRQ", label: "Irak" },
    { value: "IRN", label: "Irán" },
    { value: "IRL", label: "Irlanda" },
    { value: "BVT", label: "Isla Bouvet" },
    { value: "IMN", label: "Isla de Man" },
    { value: "CXR", label: "Isla de Navidad" },
    { value: "NFK", label: "Isla Norfolk" },
    { value: "ISL", label: "Islandia" },
    { value: "CYM", label: "Islas Caimán" },
    { value: "CCK", label: "Islas Cocos" },
    { value: "COK", label: "Islas Cook" },
    { value: "FRO", label: "Islas Feroe" },
    { value: "SGS", label: "Georgia del sur y las islas sandwich del sur" },
    { value: "HMD", label: "Isla Heard e Islas McDonald" },
    { value: "FLK", label: "Islas Malvinas" },
    { value: "MNP", label: "Islas Marianas del Norte" },
    { value: "MHL", label: "Islas Marshall" },
    { value: "PCN", label: "Pitcairn" },
    { value: "SLB", label: "Islas Salomón" },
    { value: "TCA", label: "Islas Turcas y Caicos" },
    { value: "UMI", label: "Islas de Ultramar Menores de Estados Unidos" },
    { value: "VIR", label: "Islas Vírgenes" },
    { value: "ISR", label: "Israel" },
    { value: "ITA", label: "Italia" },
    { value: "JAM", label: "Jamaica" },
    { value: "JPN", label: "Japón" },
    { value: "JEY", label: "Jersey" },
    { value: "JOR", label: "Jordania" },
    { value: "KAZ", label: "Kazajistán" },
    { value: "KEN", label: "Kenia" },
    { value: "KGZ", label: "Kirguistán" },
    { value: "KIR", label: "Kiribati" },
    { value: "KWT", label: "Kuwait" },
    { value: "LAO", label: "Lao" },
    { value: "LSO", label: "Lesoto" },
    { value: "LVA", label: "Letonia" },
    { value: "LBN", label: "Líbano" },
    { value: "LBR", label: "Liberia" },
    { value: "LBY", label: "Libia" },
    { value: "LIE", label: "Liechtenstein" },
    { value: "LTU", label: "Lituania" },
    { value: "LUX", label: "Luxemburgo" },
    { value: "MAC", label: "Macao" },
    { value: "MDG", label: "Madagascar" },
    { value: "MYS", label: "Malasia" },
    { value: "MWI", label: "Malaui" },
    { value: "MDV", label: "Maldivas" },
    { value: "MLI", label: "Malí" },
    { value: "MLT", label: "Malta" },
    { value: "MAR", label: "Marruecos" },
    { value: "MTQ", label: "Martinica" },
    { value: "MUS", label: "Mauricio" },
    { value: "MRT", label: "Mauritania" },
    { value: "MYT", label: "Mayotte" },
    { value: "MEX", label: "México" },
    { value: "FSM", label: "Micronesia" },
    { value: "MDA", label: "Moldavia" },
    { value: "MCO", label: "Mónaco" },
    { value: "MNG", label: "Mongolia" },
    { value: "MNE", label: "Montenegro" },
    { value: "MSR", label: "Montserrat" },
    { value: "MOZ", label: "Mozambique" },
    { value: "NAM", label: "Namibia" },
    { value: "NRU", label: "Nauru" },
    { value: "NPL", label: "Nepal" },
    { value: "NIC", label: "Nicaragua" },
    { value: "NER", label: "Níger" },
    { value: "NGA", label: "Nigeria" },
    { value: "NIU", label: "Niue" },
    { value: "NOR", label: "Noruega" },
    { value: "NCL", label: "Nueva Caledonia" },
    { value: "NZL", label: "Nueva Zelanda" },
    { value: "OMN", label: "Omán" },
    { value: "NLD", label: "Países Bajos" },
    { value: "PAK", label: "Pakistán" },
    { value: "PLW", label: "Palaos" },
    { value: "PSE", label: "Palestina, Estado de" },
    { value: "PAN", label: "Panamá" },
    { value: "PNG", label: "Papúa Nueva Guinea" },
    { value: "PRY", label: "Paraguay" },
    { value: "PER", label: "Perú" },
    { value: "PYF", label: "Polinesia Francesa" },
    { value: "POL", label: "Polonia" },
    { value: "PRT", label: "Portugal" },
    { value: "PRI", label: "Puerto Rico" },
    { value: "GBR", label: "Reino Unido" },
    { value: "CAF", label: "República Centroafricana" },
    { value: "CZE", label: "República Checa" },
    { value: "MKD", label: "Macedonia" },
    { value: "COG", label: "Congo" },
    { value: "DOM", label: "República Dominicana" },
    { value: "REU", label: "Reunión" },
    { value: "RWA", label: "Ruanda" },
    { value: "ROU", label: "Rumania" },
    { value: "RUS", label: "Rusia" },
    { value: "ESH", label: "Sahara Occidental" },
    { value: "WSM", label: "Samoa" },
    { value: "ASM", label: "Samoa Americana" },
    { value: "BLM", label: "San Bartolomé" },
    { value: "KNA", label: "San Cristóbal y Nieves" },
    { value: "SMR", label: "San Marino" },
    { value: "MAF", label: "San Martín" },
    { value: "SPM", label: "San Pedro y Miquelón" },
    { value: "VCT", label: "San Vicente y las Granadinas" },
    { value: "SHN", label: "Santa Helena" },
    { value: "LCA", label: "Santa Lucía" },
    { value: "STP", label: "Santo Tomé y Príncipe" },
    { value: "SEN", label: "Senegal" },
    { value: "SRB", label: "Serbia" },
    { value: "SYC", label: "Seychelles" },
    { value: "SLE", label: "Sierra leona" },
    { value: "SGP", label: "Singapur" },
    { value: "SXM", label: "Sint Maarten" },
    { value: "SYR", label: "Siria" },
    { value: "SOM", label: "Somalia" },
    { value: "LKA", label: "Sri Lanka" },
    { value: "SWZ", label: "Suazilandia" },
    { value: "ZAF", label: "Sudáfrica" },
    { value: "SDN", label: "Sudán" },
    { value: "SSD", label: "Sudán del Sur" },
    { value: "SWE", label: "Suecia" },
    { value: "CHE", label: "Suiza" },
    { value: "SUR", label: "Surinam" },
    { value: "SJM", label: "Svalbard y Jan Mayen" },
    { value: "THA", label: "Tailandia" },
    { value: "TWN", label: "Taiwán" },
    { value: "TZA", label: "Tanzania" },
    { value: "TJK", label: "Tayikistán" },
    { value: "IOT", label: "Territorio Británico del Océano Índico" },
    { value: "ATF", label: "Territorios Australes Franceses" },
    { value: "TLS", label: "Timor-Leste" },
    { value: "TGO", label: "Togo" },
    { value: "TKL", label: "Tokelau" },
    { value: "TON", label: "Tonga" },
    { value: "TTO", label: "Trinidad y Tobago" },
    { value: "TUN", label: "Túnez" },
    { value: "TKM", label: "Turkmenistán" },
    { value: "TUR", label: "Turquía" },
    { value: "TUV", label: "Tuvalu" },
    { value: "UKR", label: "Ucrania" },
    { value: "UGA", label: "Uganda" },
    { value: "URY", label: "Uruguay" },
    { value: "UZB", label: "Uzbekistán" },
    { value: "VUT", label: "Vanuatu" },
    { value: "VEN", label: "Venezuela" },
    { value: "VNM", label: "Vietnam" },
    { value: "WLF", label: "Wallis y Futuna" },
    { value: "YEM", label: "Yemen" },
    { value: "DJI", label: "Yibuti" },
    { value: "ZMB", label: "Zambia" },
    { value: "ZWE", label: "Zimbabue" }
];

export { NATIONALITY_OPTIONS };

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { MultiSelectInput } from "../../components/input/inputs";
import main from "../../styles/main.module.css";
import esES from 'rsuite/locales/es_ES';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios, { baseUrlSockets } from "../../AxiosInstance";

export default function EventList() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });

    const [events, setEvents] = useState([]);
    const [zones, setZones] = useState([]);
    const [gatesystem, setGatesystem] = useState([]);
    const [newData, setNewData] = useState(null);
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 20, totalCount: 0 });

    useEffect(() => {
        axios.get('zona/').then((response) => {
            setZones(response.data);
        });

        fetchEvents();
    }, []);

    useEffect(() => {
        axios.get('gatesystem/').then((response) => {
            setGatesystem(response.data.results);
        });

        fetchEvents();
    }, []);

    useEffect(() => {
        fetchEvents();
    }, [filters, pagination.currentPage]); // Fetch events when page changes

    useEffect(() => {
        let newSocket = new WebSocket(baseUrlSockets);

        newSocket.onopen = function () {
            console.log('Conexión establecida');
        }
        newSocket.onerror = function () {
            console.log('Error en la conexión. Intentando reconectar...');
            setTimeout(() => {
                newSocket = new WebSocket(baseUrlSockets);
            }, 1000);
        }
        newSocket.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            if (receivedMessage.type === 'event_created') {
                console.log('Añadiendo nuevo evento');
                setNewData(receivedMessage.data);
            }
        };

        newSocket.onclose = function () {
            console.log('La conexión ha sido cerrada');
        }
        return () => newSocket.close();
    }, []);

    useEffect(() => {
        if (!newData) return;
        if (events.length === 0) return;
        console.log('Longitud eventos: ' + events.length);
        if (events.some(value => value.id === newData.id)) return;
        let newEvents = [...events];
        newEvents.unshift(newData);
        setEvents(newEvents);
    }, [newData]);

    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
    };

    const configuration = {
        sunday: 'D',
        monday: 'L',
        tuesday: 'M',
        wednesday: 'Mi',
        thursday: 'J',
        friday: 'V',
        saturday: 'S',
        ok: 'Buscar',
        formattedMonthPattern: 'MMMM yyyy',
    };

    const fetchEvents = () => {
        let params = Object.keys(filters)?.map((key) => {
            if (Array.isArray(filters[key])) {
                return `${key}=${filters[key].join(',')}`
            }
            return `${key}=${filters[key]}`;
        }).join('&');

        axios.get(`events/?page=${pagination.currentPage}&${params}`).then((response) => {
            setEvents(response.data.results);
            setPagination(prev => ({
                ...prev,
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous
            }));
        });
    }

    const handleSetFormZone = (values) => {
        setFilters({
            ...filters,
            zona: values
        });
    }

    const handleSetFormGate = (values) => {
        setFilters({
            ...filters,
            gatesystem: values
        });
    }

    const handleDateRange = (values) => {
        if (values.length < 2) return;
        setFilters({
            ...filters,
            start_date: dayjs(values[0]).format("YYYY-MM-DDTHH:mm:ss"),
            end_date: dayjs(values[1]).format("YYYY-MM-DDTHH:mm:ss"),
        });
    }

    const handlePageChange = (direction) => {
        setPagination(prev => ({
            ...prev,
            currentPage: direction === 'next' ? prev.currentPage + 1 : prev.currentPage - 1
        }));
    }

    return (
        <div>
            <Header current={3} onSidebarToggle={handleSidebarToggle} />
            <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>

                <div style={{ flexShrink: '0', flexBasis: '100px', textAlign: 'left' }} className={main.listTitle}>
                    Eventos
                </div>

                <div className={main.filterContainer}>
                    <div className={`${main.filterItem}`}>
                        <img src="/filter_icon.svg" alt="filtro" width="19px" />
                        <div className={main.filterLabel}>Filtro</div>
                    </div>
                    <div style={{ width: '200px' }}>
                        <MultiSelectInput label={'Todas las zonas'} options={zones?.map((x) => { return { id: x.id, name: x.nombre_zona } })} setForm={handleSetFormZone} />
                    </div>
                    <div style={{ width: '200px' }}>
                        <CustomProvider locale={esES}>
                            <DateRangePicker
                                locale={configuration}
                                showHeader={false}
                                showOneCalendar
                                ranges={[]}
                                format="dd/MM/yyyy hh:mm"
                                showMeridian={true}
                                caretAs={IconCalendar}
                                onChange={handleDateRange}
                                placeholder="Seleccione un horario"
                            />
                        </CustomProvider>
                    </div>
                    <div style={{ width: '200px' }}>
                        <MultiSelectInput label={'Todas las barreras'} options={gatesystem?.map((x) => { return { id: x.id, name: x.nombre } })} setForm={handleSetFormGate} />
                    </div>
                </div>

                <div className={main.tableContainer}>
                    <table className={main.listTable}>
                        <thead>
                            <tr>
                                <th className={main.tableItemHeader}>Evento</th>
                                <th className={main.tableItemHeader}>Sistema de Barrera</th>
                                <th className={main.tableItemHeader}>Zona</th>
                                <th className={main.tableItemHeader}>Fecha y Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map((item) => (
                                <tr key={item.id}>
                                    <td className={main.tableItem}>{item.event}</td>
                                    <td className={main.tableItem}>{item.gate_nombre}</td>
                                    <td className={main.tableItem}>{item.zona_nombre}</td>
                                    <td className={main.tableItem}>{dayjs(item.datetime).format("DD/MM/YYYY HH:mm A")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="d-flex justify-content-between mt-3">
                    <button
                        className={`btn btn-primary`}
                        onClick={() => handlePageChange('previous')}
                        disabled={!pagination.previous}
                    >
                        Anterior
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => handlePageChange('next')}
                        disabled={!pagination.next}
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    );
}

const IconCalendar = () => {
    return (<FontAwesomeIcon icon={faChevronDown} />);
}

import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import styles from '../styles/login.module.css'

export default function PasswordInput({password, setPassword}) {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return(
        <div className="form-group">
            <div className={`${styles.textDarkBlue} ${styles.labelLeft}  mb-1 mt-4`}>Contraseña</div>
            <div className={`input-group ${styles.passwordInputGroup}`}>
                <input
                    type={passwordVisible ? "text" : "password"}
                    className={`form-control ${styles.borderDarkBlue} rounded-2 ${styles.passwordInput}`}
                    id="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <div
                    style={{width: '46px', zIndex: '9999', borderWidth: '2px'}}
                    className={`input-group-text bg-white ${styles.borderDarkBlue} ${styles.passwordToggle}`}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                >
                    {passwordVisible ?
                        <FontAwesomeIcon icon={faEyeSlash}/>
                        :
                        <FontAwesomeIcon icon={faEye}/>
                    }
                </div>
            </div>
        </div>
    )
}
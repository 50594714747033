import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import esES from 'rsuite/locales/es_ES';
import Header from "../../components/header";
import { MultiSelectInput } from "../../components/input/inputs";
import styles from "../../styles/authorizations.module.css";
import main from "../../styles/main.module.css";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios, { baseUrlSockets } from "../../AxiosInstance";
import ModalComponent from "../../components/modal/modalcomponent";

export default function HistoryList() {
    const [numberForm, setNumberForm] = useState({});
    const [zones, setZones] = useState([]);
    const [gatesystem, setGatesystem] = useState([]);
    const [zoneForm, setZoneForm] = useState([]);
    const [scheduleForm, setScheduleForm] = useState({ scheduleSelectedId: null });
    const [vehicleForm, setVehicleForm] = useState({ vehicleSelectedId: null });
    const [history, setHistory] = useState([]);
    const [filters, setFilters] = useState({});
    const [showPhoto, setShowPhoto] = useState(false);
    const [photoDialog, setphotoDialog] = useState({ url: '' });
    const [newData, setNewData] = useState(null);
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null, currentPage: 1 });
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });


    useEffect(() => {
        axios.get('zona/').then((response) => {
            setZones(response.data);
        });

        fetchHistory();
    }, []);

    useEffect(() => {
        axios.get('gatesystem/').then((response) => {
            setGatesystem(response.data.results);
        });

        fetchHistory();
    }, []);

    useEffect(() => {
        let newSocket = new WebSocket(baseUrlSockets);

        newSocket.onopen = function () {
            console.log('conexion establecida')
        }
        newSocket.onerror = function () {
            console.log('error en conexion. Intendado reconectar...')
            setTimeout(() => {
                newSocket = new WebSocket(baseUrlSockets);
            }, 1000);

        }
        newSocket.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data)
            if (receivedMessage.type === 'history_created') {
                console.log('Añadiendo nuevo historico')
                setNewData(receivedMessage.data)
            }
        };

        newSocket.onclose = function () {
            console.log('La conexion ha sido cerrada')
        }
        return () => newSocket.close();
    }, []);

    useEffect(() => {
        if (!newData) return;
        if (history.length === 0) return;
        console.log('longitud historial: ' + history.length)
        if (history.some(value => value.id === newData.id)) return;
        let newHistory = [...history];
        newHistory.unshift(newData);
        setHistory(newHistory);
    }, [newData]);

    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
        console.log(isCollapsed);
    };

    const configuration = {
        sunday: 'D',
        monday: 'L',
        tuesday: 'M',
        wednesday: 'Mi',
        thursday: 'J',
        friday: 'V',
        saturday: 'S',
        ok: 'Buscar',
        formattedMonthPattern: 'MMMM yyyy',
    };


    useEffect(() => {
        fetchHistory();
    }, [filters, pagination.currentPage]);

    const fetchHistory = () => {
        let params = Object.keys(filters)?.map((key) => {
            if (Array.isArray(filters[key])) {
                return `${key}=${filters[key].join(',')}`
            }
            return `${key}=${filters[key]}`;
        }).join('&');

        axios.get(`history/?page=${pagination.currentPage}&${params}`).then((response) => {
            setHistory(response.data.results);
            setPagination(prev => ({
                ...prev,
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous
            }));
        });
    }

    const handleSetFormZone = (values) => {
        setFilters({
            ...filters,
            zona: values
        });
    }

    const handleSetFormGate = (values) => {
        setFilters({
            ...filters,
            gatesystem: values
        });
    }

    const handleDateRange = (values) => {
        if (values.length < 2) return;
        setFilters({
            ...filters,
            start_date: dayjs(values[0]).format("YYYY-MM-DDTHH:mm:ss"),
            end_date: dayjs(values[1]).format("YYYY-MM-DDTHH:mm:ss"),
        });
    }

    const handlePageChange = (direction) => {
        setPagination(prev => ({
            ...prev,
            currentPage: direction === 'next' ? prev.currentPage + 1 : prev.currentPage - 1
        }));
    }

    return (
        <div>
            <Header current={2} onSidebarToggle={handleSidebarToggle} />

            <ModalComponent showModal={showPhoto} message={''} imgData={photoDialog.url} duration={3000} onClose={() => { setShowPhoto(false) }} />

            <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>
                <div className={'d-flex align-items-center'} style={{ gap: '5px', marginBottom: '1rem' }}>
                    <div style={{ flexShrink: '0', flexBasis: '100px', textAlign: 'left' }}
                        className={`${styles.listTitle} `}>
                        Historial
                    </div>
                </div>
                <div className={main.filterContainer}>
                    <div className={`${main.filterItem}`}>
                        <img src="/filter_icon.svg" alt="filtro" width="19px" />
                        <div>
                            Filtro
                        </div>
                    </div>
                    <div style={{ width: '200px' }}>
                        <MultiSelectInput label={'Todas las zonas'} options={zones?.map((x) => { return { id: x.id, name: x.nombre_zona } })} setForm={handleSetFormZone} />
                    </div>
                    <div style={{ width: '200px' }}>
                        <CustomProvider locale={esES}>
                            <DateRangePicker
                                locale={configuration}
                                showHeader={false}
                                showOneCalendar
                                ranges={[]}
                                format="dd/MM/yyyy hh:mm"
                                showMeridian={true}
                                caretAs={IconCalendar}
                                onChange={handleDateRange}
                                placeholder="Seleccione un horario"
                            />
                        </CustomProvider>
                    </div>
                    <div style={{ width: '200px' }}>
                        <MultiSelectInput label={'Todas las barreras'} options={gatesystem?.map((x) => { return { id: x.id, name: x.nombre } })} setForm={handleSetFormGate} />
                    </div>





                    <div style={{ marginLeft: 'auto', display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: "700" }}>Total vehículos</span>
                        <span style={{ marginLeft: '5px', color: '#8699CB', fontWeight: '600' }}>{pagination.count}</span>
                    </div>

                </div>

                <table className={`${styles.listTable} mt-3`}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '200px' }}>Sistema de Barrera</th>
                            <th className={`${styles.tableItemHeader}`}>Tipo</th>
                            <th className={`${styles.tableItemHeader}`}>Matrícula</th>
                            <th className={`${styles.tableItemHeader}`}>Placa de mina</th>
                            <th className={`${styles.tableItemHeader}`}>Zonas</th>
                            <th className={`${styles.tableItemHeader}`}>Ultima Apertura</th>
                            <th className={`${styles.tableItemHeader}`} ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.map((item) => (
                            <tr key={item.id}>
                                <td style={{ minWidth: '120px' }} >{item.gate_nombre}</td>
                                <td className={`${styles.tableItem}`} >{item.model}</td>
                                <td className={`${styles.tableItem}`} >{item.license_plate}</td>
                                <td className={`${styles.tableItem}`} >{item.mining_plate}</td>
                                <td className={`${styles.tableItem}`} >{item.zona_nombre}</td>
                                <td className={`${styles.tableItem}`} >{dayjs(item.datetime).format("DD/MM/YYYY HH:mm A")}</td>
                                <td className={`${styles.tableItem}`} >
                                    <div style={{ gap: '10px', display: 'flex' }}>
                                        <img src={`${item.photo}`} alt="placa" style={{ width: '40px', height: '30px', objectFit: 'cover', borderRadius: '5px' }} onClick={() => {
                                            setphotoDialog({ url: item.photo });
                                            setShowPhoto(true);
                                        }} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="d-flex justify-content-between mt-3">
                    <button
                        className={`btn btn-primary`}
                        onClick={() => handlePageChange('previous')}
                        disabled={!pagination.previous}
                    >
                        Anterior
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => handlePageChange('next')}
                        disabled={!pagination.next}
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    );
}

const IconCalendar = () => {
    return (<FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>);
}

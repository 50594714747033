import { Modal as BootStrapModal } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../input/inputs";
import styles from './modal.module.css';

import React from 'react';

export default function Modal({ show, setter, name, description, children, buttonName, onClick, hideCancel, onClickCancel, imgUrl }) {
    const handleCancelButton = () => {
        setter(false)
        if (onClickCancel) {
            onClickCancel()
        }
    }

    return (

        <BootStrapModal show={show} className={styles.container} centered={true}  >
            <div className={styles.header}>
                <div className="d-flex align-items-center gap-1">
                    {imgUrl && <img src={imgUrl}></img>}
                    <div>{name}</div>
                </div>
                <div>{description}</div>
            </div>

            <div className={styles.body}>
                {children}
            </div>

            <div className={styles.footer}>
                {!hideCancel && <SecondaryButton onClick={handleCancelButton} name={'Cancelar'} />}
                <div className={'ms-3'}>
                    <PrimaryButton name={buttonName} onClick={onClick} />
                </div>
            </div>
        </BootStrapModal>
    );
}




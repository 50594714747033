import React, {useState} from "react";
import Header from "../../components/header";
import styles from "../../styles/authorizations.module.css";

export default function VehicleList() {
    const [vehicles, setVehicles] = useState([
        {}
    ]);

    return (
        <div style={{height: '100% !important', marginLeft: '250px'}}>
            <Header current={2}/>

            <div className={'bg-light vh-100 px-5 py-3'} style={{marginTop: '120px'}}>
                <div className={`${styles.createContainer }`}>
                    <div className={'d-flex align-items-center'}>
                        <div className={`${styles.listTitle}`}>Lista de vehículos</div>
                        <div className={'ps-2'} style={{color: '#8699CB', fontWeight: '500'}}>total {vehicles.length}</div>
                    </div>
                </div>

                <table className={`${styles.listTable} mt-3`}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th className={`${styles.tableItemHeader}`}>Personas</th>
                        <th className={`${styles.tableItemHeader}`}>Estado</th>
                        <th className={`${styles.tableItemHeader}`}>Tipo</th>
                        <th className={`${styles.tableItemHeader}`}>Placa</th>
                        <th className={`${styles.tableItemHeader}`}>Zonas</th>
                        <th className={`${styles.tableItemHeader}`}>Última apertura</th>
                        <th className={`${styles.tableItemHeader}`}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map((item, index) => (
                        <tr key={index}>
                            <td className={`${styles.tableItem}`}>{item.minestar_code}</td>
                            <td className={`${styles.tableItem}`}></td>
                            <td className={`${styles.tableItem}`}></td>
                            <td className={`${styles.tableItem}`}>{item.other_data?.placa}</td>
                            <td className={`${styles.tableItem}`}>1</td>
                            <td className={`${styles.tableItem}`}></td>
                            <td className={`${styles.tableItem}`}></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {Pagination} from "react-bootstrap";
import axios from "../AxiosInstance";

export default function PaginationComponent({prop, defaultUrl, func, limit}) {
    const paginationLimit = limit; // same as settings.py
    const [last, setLast] = useState(0);
    const [active, setActive] = useState(1);

    useEffect(() => {
        axios.get(prop  + '/').then((response) => {
            setLast(Math.ceil(response.data.count / paginationLimit));
        })
    }, []);

    useEffect(() => {
        if (prop === defaultUrl) return;
        axios.get(prop + '&limit=' + paginationLimit + '&offset=' + paginationLimit * (active - 1) + "&page=" + active).then((response) => {
            func(response.data.results)
        })
    }, [prop]);

    const paginationGo = (page) => {
        axios.get(prop + '&limit=' + paginationLimit + '&offset=' + paginationLimit * (page - 1) + "&page=" + page).then((response) => {
            func(response.data.results)
            setActive(page);
        })
    };

    return (
        <Pagination>
            {active > 3 && <Pagination.Ellipsis />}
            {active > 2 && <Pagination.Item onClick={() => paginationGo(active-2)}>{active - 2}</Pagination.Item>}
            {active > 1 && <Pagination.Item onClick={() => paginationGo(active-1)}>{active - 1}</Pagination.Item>}
            <Pagination.Item active>{active}</Pagination.Item>
            {active < last - 0 && <Pagination.Item onClick={() => paginationGo(active+1)}>{active + 1}</Pagination.Item>}
            {active < last - 1 && <Pagination.Item onClick={() => paginationGo(active+2)}>{active + 2}</Pagination.Item>}
            {active < last - 2 && <Pagination.Ellipsis/>}
        </Pagination>
    )
}
import { faCalendar, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosInstance";
import Header from "../../../components/header";
import { MenuButton, SelectInput } from "../../../components/input/inputs";
import Modal from "../../../components/modal/modal";
import main from "../../../styles/main.module.css";
import styles from "../../users/styles/list.module.css";
const INITIAL_GATE_PROGRAMMING_FORM = {

    // id: null,
    // state: "",
    // day_of_week: "",
    // start_time: "",
    // end_time: "",
    // created_at: "",
    // received: true,
    // open_task_id: null,
    // close_task_id: null,
    // gate_system: null

}

export default function GateProgrammingList() {
    const [showModal, setShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    // const [gateSystemForm, setForm] = useState({});
    const [gateSystemForm, setGateSystemForm] = useState(INITIAL_GATE_PROGRAMMING_FORM);
    const [itemMenuOpen, setItemMenuOpen] = useState({
        idGate: null,
    });
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });

    const [gates, setGates] = useState([]);


    const days = [
        { id: 0, name: 'Domingo' },
        { id: 1, name: 'Lunes' },
        { id: 2, name: 'Martes' },
        { id: 3, name: 'Miércoles' },
        { id: 4, name: 'Jueves' },
        { id: 5, name: 'Viernes' },
        { id: 6, name: 'Sábado' },
    ]
    const hours = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
    ]
    const minutes = Array.from(Array(60).keys()).map(val => ({ id: val, name: String(val) }));

    const amPm = [
        { id: 1, name: 'AM' },
        { id: 2, name: 'PM' },
    ]
    const [gatesProgramming, setGatesProgramming] = useState([]

    );

    useEffect(() => {
        (async () => {
            const gateList = await getGateList()
            setGates(gateList)
            const gateProgrammingList = await getGateProgrammingList();
            // console.log(gateProgrammingList)
            setGatesProgramming(gateProgrammingList.results)
        })()
    }, [])

    const getGateProgrammingList = () => {
        return axiosInstance.get('programar_apertura/').then(
            (response) => response.data
        )
    }

    const getGateList = () => {
        return axiosInstance.get('gatesystem/').then(
            (response) => response.data
        )
    }

    const handleGateSystemForm = () => {
        const { id, ...rest } = gateSystemForm
        const dayOfWeek = days.find((day) => gateSystemForm.day_of_week === day.id).id.toString()

        const startAmPm = gateSystemForm.start_am_pm
        const startHoras = gateSystemForm.start_horas
        const startMinutos = gateSystemForm.start_minutos

        const endAmPm = gateSystemForm.end_am_pm //1 - am, 2 - pm
        const endHoras = gateSystemForm.end_horas
        const endMinutos = gateSystemForm.end_minutos

        //TODO: validar form
        const sendToApi = {
            "state": "open",
            "day_of_week": dayOfWeek,
            "start_time": convertirHoraAMPMa24Horas(startHoras, startMinutos, startAmPm),
            "end_time": convertirHoraAMPMa24Horas(endHoras, endMinutos, endAmPm),
            "gate_system": gateSystemForm.gate_system
        }
        console.log(sendToApi)
        if (!id) {
            axiosInstance.post('programar_apertura/', sendToApi).then(() =>
                getGateProgrammingList()
            ).then((gateList) => {
                setGatesProgramming(gateList)
                setShowCreateModal(false)
                resetGateSystemForm()
            })
        } else {
            axiosInstance.put('programar_apertura/' + id + '/', sendToApi).then(() =>
                getGateProgrammingList()
            ).then((gateList) => {
                setGatesProgramming(gateList)
                setShowCreateModal(false)
                resetGateSystemForm()
            })
        }
        // console.log(gateSystemForm)
    }

    const handleItemMenu = (id) => {
        const prevValue = { ...itemMenuOpen }
        if (prevValue.idGate === id) {
            setItemMenuOpen({
                idGate: null,
            })

        } else {
            setItemMenuOpen({
                idGate: id,
            })
        }

    }

    const handleEditGate = (gateProgramming) => {

        const [start_horas, start_minutos, start_am_pm] = convertirHora(gateProgramming.start_time)
        // console.log([start_horas, start_minutos, start_am_pm])

        const [end_horas, end_minutos, end_am_pm] = convertirHora(gateProgramming.end_time)
        // console.log(gateProgramming.day_of_week)
        // console.log(Number(gateProgramming.day_of_week))

        setGateSystemForm({
            id: gateProgramming.id,
            day_of_week: Number(gateProgramming.day_of_week),
            start_horas: Number(start_horas),
            start_minutos: Number(start_minutos),
            start_am_pm: start_am_pm,// start_horas >= 12 ? 1 : 0,
            end_horas: Number(end_horas),
            end_minutos: Number(end_minutos),
            end_am_pm: end_am_pm,// end_horas >= 12 ? 1 : 0,
            gate_system: gateProgramming.gate_system
        })
        setShowCreateModal(true)
    }

    const handleDeleteGate = (gateProgramming) => {
        setShowDeleteModal(true)
        setGateSystemForm({
            id: gateProgramming.id,

        })
    }

    const handleConfirmDelete = () => {
        const { id } = gateSystemForm

        axiosInstance.delete('programar_apertura/' + id + '/').then(() =>
            getGateProgrammingList()
        ).then((gateList) => {
            setGatesProgramming(gateList)
            setShowDeleteModal(false)
            resetGateSystemForm()
        })
    }

    const resetGateSystemForm = () => {
        setGateSystemForm(INITIAL_GATE_PROGRAMMING_FORM)
    }
    // const resetGateSystemForm = () => {
    //     setGateSystemForm({})
    // }
    const handleCancelCreate = () => {
        console.log(gateSystemForm)
        resetGateSystemForm()
    }
    const handleCancelProgram = () => {
        resetGateSystemForm()
    }

    const filterGates = (gates) => {
        return gates.map((gate_filter) => {
            return {
                name: gate_filter.nombre,
                id: gate_filter.id,
            }
        })
    }

    //TODO: borrar
    const handleForm = () => {

        // console.log(form)
        const dayOfWeek = days.find((day) => gateSystemForm.day_of_week === day.id).id.toString()

        const startAmPm = gateSystemForm.start_am_pm
        const startHoras = gateSystemForm.start_horas
        const startMinutos = gateSystemForm.start_minutos

        const endAmPm = gateSystemForm.end_am_pm //1 - am, 2 - pm
        const endHoras = gateSystemForm.end_horas
        const endMinutos = gateSystemForm.end_minutos

        //TODO: validar form
        const sendToApi = {
            "state": "open",
            "day_of_week": dayOfWeek,
            "start_time": convertirHoraAMPMa24Horas(startHoras, startMinutos, startAmPm),
            "end_time": convertirHoraAMPMa24Horas(endHoras, endMinutos, endAmPm),
            "gate_system": gateSystemForm.gate_system
        }
        // console.log(sendToApi)
        axiosInstance.post('programar_apertura/', sendToApi).then(() => {
            (async () => {
                const gateList = await getGateList()
                setGates(gateList)
                setShowCreateModal(false)
                resetGateSystemForm()
            }

            )()

        })
    }

    function convertirHoraAMPMa24Horas(horas, minutos, periodo) {
        // Convertir la hora a 24 horas
        if (periodo === 2 && horas < 12) {
            horas += 12;
        } else if (periodo === 1 && horas === 12) {
            horas = 0;
        }

        // Formatear la hora en formato de 24 horas
        return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + '00';
    }

    function convertirHora(hora24) {
        const [horas, minutos] = hora24.split(':'); // Separar la hora y los minutos
        let hora12 = parseInt(horas, 10) % 12 || 12; // Convertir las horas a formato de 12 horas
        const ampm = parseInt(horas, 10) >= 12 ? 2 : 1; // Determinar si es AM o PM
        return [hora12.toString(), minutos, ampm]; // Devolver un array con la hora, los minutos y AM/PM
    }

    function obtenerDiaSemana(numero) {
        const diasSemana = {
            '0': 'Domingo',
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miércoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sábado',
        };

        const dia = diasSemana[numero];
        return dia ? dia : ' - ';
    }
    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
    };


    return (
        <div style={{ height: '100% !important', marginLeft: '315px' }}>
            <Header current={0} subCurrent={1} onSidebarToggle={handleSidebarToggle} />

            <Modal show={showCreateModal} setter={setShowCreateModal} name={gateSystemForm.id ? 'Editar' : 'Programar'} buttonName={gateSystemForm.id ? 'Guardar' : 'Programar'} onClick={handleGateSystemForm} onClickCancel={handleCancelCreate}>
                <div>
                    <div className={"d-flex flex-row align-items-center w-100"}>
                        <div style={{ flexBasis: '40%' }}>Día</div>
                        <div className="mb-3" style={{ flexBasis: '60%', }}>
                            <SelectInput placeholder={'Selecione'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'day_of_week'} options={days} />
                        </div>
                    </div>

                    <div className={"d-flex flex-row align-items-center w-100"}>
                        <div style={{ flexBasis: '40%' }}>Apertura</div>
                        <div className="gap-2 d-flex align-items-center" style={{ flexBasis: '60%' }}>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'00'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'start_horas'} options={hours} />
                            </div>
                            <div>:</div>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'00'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'start_minutos'} options={minutes} />
                            </div>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'-'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'start_am_pm'} options={amPm} />
                            </div>
                        </div>
                    </div>
                    <div className={"d-flex flex-row align-items-center w-100"}>
                        <div style={{ flexBasis: '40%' }}>Cierre</div>
                        <div className="gap-2 d-flex align-items-center" style={{ flexBasis: '60%' }}>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'00'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'end_horas'} options={hours} />
                            </div>
                            <div>:</div>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'00'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'end_minutos'} options={minutes} />
                            </div>
                            <div className="mb-3" style={{ flexBasis: '30%' }}>
                                <SelectInput placeholder={'-'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'end_am_pm'} options={amPm} />
                            </div>
                        </div>
                    </div>

                    <div className={"d-flex flex-row align-items-center w-100"}>
                        <div style={{ flexBasis: '40%' }}>Barrera</div>
                        <div className="mb-3" style={{ flexBasis: '60%' }}>
                            <SelectInput placeholder={'Seleccione'} form={gateSystemForm} setForm={setGateSystemForm} internalName={'gate_system'} options={filterGates(gates)} />
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal show={showDeleteModal} setter={setShowDeleteModal} name={'Eliminar Barrera'} buttonName={'Eliminar'} onClickCancel={handleCancelCreate} onClick={handleConfirmDelete}>
                <div>
                    <div className={"d-flex flex-row align-items-center w-100"}>

                        {`¿Está seguro que desea eliminar tranquera ${gateSystemForm.nombre}?`}
                    </div>
                </div>
            </Modal>


            <div className={'bg-light h-100 px-5 py-3'} style={{ marginTop: '120px' }}>

                <div className={`${styles.createContainer}`}>
                    <div className={'d-flex align-items-center'}>
                        <div className={`${styles.listTitle}`}>Programación de Barreras</div>
                        <div className={'ps-2'} style={{ color: '#8699CB', fontWeight: '500' }}>total {gatesProgramming.length}</div>
                    </div>
                    <div className={`btn d-flex ${main.secondaryButton}`} onClick={() => {
                        setShowCreateModal(true);
                    }}>
                        <FontAwesomeIcon icon={faCalendar} />
                        <div className={'ps-2'}>Programar</div>
                    </div>

                </div>

                <table className={`${styles.userTable}`}>
                    <thead>
                        <tr>
                            <th>Nombre del Controlador</th>
                            <th>IP</th>
                            <th>Localización</th>
                            <th>Programación de apertura</th>
                            <th>Zona</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {gatesProgramming.map((gateProgramming, index) => {
                            const gate = gates.find((gate) => gate.id === gateProgramming.gate)
                            // console.log(gate)
                            // const zone = zones.find((zone)=>{zone.id===gateProgramming.zone})
                            const [startHour, startMinutes] = gateProgramming.start_time.split(':');
                            const [endHour, endMinutes] = gateProgramming.end_time.split(':');

                            const startTime = new Date();
                            startTime.setHours(startHour);
                            startTime.setMinutes(startMinutes);

                            const endTime = new Date();
                            endTime.setHours(endHour);
                            endTime.setMinutes(endMinutes);
                            return (

                                <tr key={index}>
                                    <td style={{ minWidth: '210px' }}>{gate.nombre}</td>
                                    <td style={{ width: '100px' }}>{gate.ip_address}</td>
                                    <td style={{ width: '100px' }} className={'h-100'}>
                                        <div className="d-flex justify-content-center">
                                            <div onClick={() => {
                                                window.open(`https://www.google.com/maps?q=${gate.latitud},${gate.longitud}`, '_blank');
                                            }} className={'d-flex justify-content-center align-items-center'} style={{
                                                cursor: 'pointer',
                                                borderRadius: '6px',

                                                width: '30px',
                                                height: '30px',
                                                backgroundColor: "#F1F7FD",
                                                border: "1px solid #45A6FF",
                                            }}>
                                                <FontAwesomeIcon icon={faLocationPin} color={'#45A6FF'} />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span style={{ color: 'black' }}>{gateProgramming.day_of_week && obtenerDiaSemana(gateProgramming.day_of_week)}</span>
                                        <span>{gateProgramming.created_at && `  desde: ${dayjs(startTime).format("hh:mm A")}  hasta ${dayjs(endTime).format("hh:mm A")} `}</span>
                                    </td>
                                    <td> - </td>
                                    <td>
                                        <MenuButton onClick={() => handleItemMenu(gateProgramming.id)}>
                                            {itemMenuOpen.idGate === gateProgramming.id && (
                                                <div className={`${styles.dropdown}`}>
                                                    <div onClick={() => handleEditGate(gateProgramming)} className={`${styles.dropdownItem}`}>Editar</div>
                                                    <div onClick={() => handleDeleteGate(gateProgramming)} className={`${styles.dropdownItem}`}>Eliminar</div>
                                                </div>
                                            )}

                                        </MenuButton>
                                        {/* <div className="position-relative">
                                            <img src="/menu_button.svg" alt="menu" onClick={() => handleItemMenu(gateProgramming.id)} style={{ cursor: 'pointer' }} />
                                        </div> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from '../../AxiosInstance';
import Header from "../../components/header";
import main from '../../styles/main.module.css';
import styles from './styles/list.module.css';

export default function UserList() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [users, setUsers] = useState([]);

    const url = useState("users/?");
    const defaultUrl = useState("users/?");
    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
        console.log(isCollapsed);
    };

    useEffect(() => {
        axios.get('users/').then((response) => {
            // console.log(response.data)
            setUsers(response.data)
        });
    }, []);

    /*const deleteUser = (user_id) => {
        axios.delete('user/' + user_id + '/').then((response) => {
            window.location.reload();
        });
    }*/

    const pullData = (data) => {
        setUsers(data);
    }

    return (
        <div>
            <Header current={1} onSidebarToggle={handleSidebarToggle}/>
            <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>
                <div className={`${styles.createUserContainer}`}>
                    <div className={`${styles.userListTitle}`}>Lista de usuarios</div>
                    <Link to="/users/create">
                        <button className={`btn ${main.mainButton}`}>Crear usuario</button>
                    </Link>
                </div>
                <table className={`${styles.userTable}`}>
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Email</th>
                            <th className={`${styles.permission}`}>Permiso</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td className={`${styles.permission}`}>{user.is_superuser ? "Admin" : "Standard"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* <PaginationComponent prop={url} defaultUrl={defaultUrl} func={pullData} limit={5} /> */}
            </div>
        </div>
    )
}
import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import styles from '../../styles/authorization_modals.module.css'
import axios from "../../AxiosInstance";

export default function ErrorModal() {
    useEffect(() => {
        setTimeout(() => {
            axios.post('processing_reset/').then((response) => {
                window.location.replace('/modal/welcome', {state: "fullscreen"});
            }).catch((err) => {
                window.location.replace('/modal/welcome', {state: "fullscreen"});
            })
        }, 30000);
    }, []);

    return(
        <div className={`${styles.background_red} h-100`}>
            <div className={styles.container}>
                <div className={styles.icon_gross}>
                    <FontAwesomeIcon icon={faExclamation} color="#FFF" size="3x"/>
                </div>
                <div className={styles.text}>ERROR</div>
                <div className={styles.text_2}>Comunicarse con la central:</div>
                <div className={styles.text_2} >258 258 259</div>
            </div>
        </div>
    )
}
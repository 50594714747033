import React, { useEffect, useState } from 'react';
import axiosInstance from '../../AxiosInstance';
import './ScheduleModal.css';

const daysOfWeek = ['L', 'Ma', 'Mi', 'J', 'V', 'S', 'D'];
const SCHEDULE_DEFAULT = [{
    id: null,
    days: [0, 4],
    open: { hour: 0, minute: 0, period: 'PM' },
    close: { hour: 0, minute: 0, period: 'PM' },
}]

const ScheduleModal = ({ onClose, onSave, idGate }) => {
    const [schedules, setSchedules] = useState(SCHEDULE_DEFAULT);
    const [schedulesAtServer, setSchedulesAtServer] = useState(SCHEDULE_DEFAULT);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setSchedules([])
        setSchedulesAtServer([])
        axiosInstance.get('programar_apertura/', {
            params: {
                gatesystem: idGate,
            }
        }).then(response => {
            const lista = response.data.results
            setSchedulesAtServer(lista)

            // console.log(response.data)
            setSchedules(
                lista.map(({ start_time, end_time, day_of_week, id }) => {
                    const [start_horas, start_minutos, start_am_pm] = convertirHora(start_time)
                    const [end_horas, end_minutos, end_am_pm] = convertirHora(end_time)

                    return {
                        id: id,
                        days: day_of_week.split(',').map(e => Number(e)),
                        open: { hour: start_horas, minute: start_minutos, period: start_am_pm },
                        close: { hour: end_horas, minute: end_minutos, period: end_am_pm },
                    }
                })
            )
        })

    }, [])
    useEffect(() => {

    }, [isLoading])


    const handleGateProgrammingSystemForm = async () => {
        setIsLoading(true)
        for (const schedule of schedules) {
            // console.log(schedule)
            const dayOfWeek = schedule.days.join(',')

            const startAmPm = schedule.open.period
            const startHoras = schedule.open.hour
            const startMinutos = schedule.open.minute

            const endAmPm = schedule.close.period//1 - am, 2 - pm
            const endHoras = schedule.close.hour
            const endMinutos = schedule.close.minute

            const sendToApi = {
                "state": "open",
                "day_of_week": dayOfWeek,
                "start_time": convertirHoraAMPMa24Horas(startHoras, startMinutos, startAmPm),
                "end_time": convertirHoraAMPMa24Horas(endHoras, endMinutos, endAmPm),
                "gate": idGate
            }
            console.log(sendToApi)
            if (schedule.id === null || schedule.id === undefined) {
                await axiosInstance.post('programar_apertura/', sendToApi)
            } else if (schedulesAtServer.some(e => e.id === schedule.id)) {
                await axiosInstance.put('programar_apertura/' + schedule.id + '/', sendToApi)
            } else {
                // await axiosInstance.delete('programar_apertura/' + schedule.id + '/', sendToApi)
            }
        }

        for (const scheduleServer of schedulesAtServer) {
            if (!schedules.some(sch => sch.id === scheduleServer.id)) {
                await axiosInstance.delete('programar_apertura/' + scheduleServer.id + '/')
            }
        }
        onSave()


    }


    function convertirHora(hora24) {
        const [horas, minutos] = hora24.split(':'); // Separar la hora y los minutos
        let hora12 = parseInt(horas, 10) % 12 || 12; // Convertir las horas a formato de 12 horas
        const ampm = parseInt(horas, 10) >= 12 ? 'PM' : 'AM'; // Determinar si es AM o PM
        return [hora12, Number(minutos), ampm]; // Devolver un array con la hora, los minutos y AM/PM
    }

    function convertirHoraAMPMa24Horas(horas, minutos, periodo) {
        // Convertir la hora a 24 horas
        if (periodo === 'PM' && horas < 12) {
            horas += 12;
        } else if (periodo === 'AM' && horas === 12) {
            horas = 0;
        }

        // Formatear la hora en formato de 24 horas
        return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + '00';
    }

    const handleDayToggle = (index, day) => {
        const newSchedules = [...schedules];
        const dayIndex = newSchedules[index].days.indexOf(day);
        if (dayIndex === -1) {
            newSchedules[index].days.push(day);
        } else {
            newSchedules[index].days.splice(dayIndex, 1);
        }
        setSchedules(newSchedules);
    };

    const handleTimeChange = (index, type, timeType, value) => {
        const newSchedules = [...schedules];
        newSchedules[index][type][timeType] = value;
        setSchedules(newSchedules);
    };

    const addSchedule = () => {
        setSchedules([...schedules, {
            id: null,
            days: [],
            open: { hour: 0, minute: 0, period: 'PM' },
            close: { hour: 0, minute: 0, period: 'PM' },
        }]);
    };

    const removeSchedule = (index) => {
        const newSchedules = [...schedules];
        newSchedules.splice(index, 1);
        setSchedules(newSchedules);
    };

    return (
        <div className="modalOverlay" onClick={onClose}>
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                <div className="icon-text-container">
                    <img src="/not_schedule.svg" alt="Icono de Programar" className="icon" />
                    <h2 className="text">Programar <br /></h2>
                </div>
                <div className="scheduleTable" >
                    <div className="scheduleHeader">
                        <span>Día(s)</span>
                        <span>Apertura</span>
                        <span>Cierre</span>
                    </div>
                    {schedules.map((schedule, index) => (
                        <div key={index} className="scheduleRow">
                            <div className="days">
                                {daysOfWeek.map((day, dayIndex) => (
                                    <button
                                        key={dayIndex}
                                        className={`dayButton ${schedule.days.includes(dayIndex) ? 'selected' : ''}`}
                                        onClick={() => handleDayToggle(index, dayIndex)}
                                    >
                                        {day}
                                    </button>
                                ))}
                            </div>
                            <div className="times">
                                <select value={schedule.open.hour} onChange={(e) => handleTimeChange(index, 'open', 'hour', parseInt(e.target.value))}>
                                    {Array.from({ length: 12 }, (_, i) => <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>)}
                                </select>
                                <select value={schedule.open.minute} onChange={(e) => handleTimeChange(index, 'open', 'minute', parseInt(e.target.value))}>
                                    {Array.from({ length: 60 }, (_, i) => <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>)}
                                </select>
                                <select value={schedule.open.period} onChange={(e) => handleTimeChange(index, 'open', 'period', e.target.value)}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>
                            <div className="times">
                                <select value={schedule.close.hour} onChange={(e) => handleTimeChange(index, 'close', 'hour', parseInt(e.target.value))}>
                                    {Array.from({ length: 12 }, (_, i) => <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>)}
                                </select>
                                <select value={schedule.close.minute} onChange={(e) => handleTimeChange(index, 'close', 'minute', parseInt(e.target.value))}>
                                    {Array.from({ length: 60 }, (_, i) => <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>)}
                                </select>
                                <select value={schedule.close.period} onChange={(e) => handleTimeChange(index, 'close', 'period', e.target.value)}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>
                            <button onClick={() => removeSchedule(index)} className="deleteButton">&#128465;</button>
                        </div>
                    ))}
                    <button onClick={addSchedule} className="addButton">+</button>
                </div>
                <div className="actions">
                    <button onClick={onClose} className="cancelButton">Cancelar</button>
                    <button className={`saveButton`}
                        style={isLoading ? { backgroundColor: 'grey' } : {}}
                        onClick={() => {
                            if (!isLoading) {
                                handleGateProgrammingSystemForm()
                            }
                        }
                        }
                    >Programar</button>
                </div>
            </div>
        </div>
    );
};

export default ScheduleModal;

import React, {useEffect, useState} from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import styles from "../../styles/crud.module.css";
import axios from "../../AxiosInstance";
import {Button} from "react-bootstrap";

export default function AuthorizationCreate() {
    const [form, setForm] = useState({});

    useEffect(() => {
        axios.get('sistema/').then((response) => {
            setForm(response.data.results[0]);
        })
    }, []);

    const setField = (name, value) => {
        setForm({
            ...form,
            [name]: value
        });
    }

    const submit = () => {
        axios.put('sistema/1/', form).then((response) => {
            console.log(form);
        }).then((response) => {
            window.location.replace('/authorization/list/');
        });
    }

    return (
        <div style={{height: '100% !important;', width: '100vw'}}>
            <Header/>

            <div className="container-fluid bg-light vh-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-6 col-lg-3 rounded p-4 bg-white shadow" style={{minWidth: '450px'}}>
                        <div className={styles.crudTitle}>Configuración</div>
                            <div className="form-group">
                                <div className={`${styles.labelLeft} mb-1 mt-4`}>Importar imagen logo</div>
                                <input type="file" className={`form-control ${styles.borderDarkBlue} rounded-2`} style={{borderWidth: '2px'}} id="username" placeholder="JPG, PNG" onChange={(event) => setField("logo", event.target.value)}/>
                            </div>

                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Seleccionar número de cámaras</div>
                            <div className={styles.customRadio}>
                                <input type="radio" id="1camera" name="cameras" checked={form.num_cameras === 1} onChange={(event) => setField("num_cameras", 1)}/>
                                <label htmlFor="1camera">1 Camara</label>
                                <input type="radio" id="2cameras" name="cameras" checked={form.num_cameras === 2} onChange={(event) => setField("num_cameras", 2)}/>
                                <label htmlFor="2cameras">2 Cámaras</label>
                            </div>

                            <div className="form-group">
                                <div className={`${styles.labelLeft} mb-1 mt-4`}>Seleccionar Zona</div>
                                <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="username" placeholder="Entrada" value={form.zone} onChange={(event) => setField("zone", event.target.value)}/>
                            </div>

                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Seleccionar sistema</div>
                            <div className={styles.customRadio}>
                                <input type="radio" id="offline" name="system" checked={form.system_type === 0} onChange={(event) => setField("system_type", 0)}/>
                                <label htmlFor="offline">Offline</label>
                                <input type="radio" id="online"  name="system" checked={form.system_type === 1} onChange={(event) => setField("system_type", 1)}/>
                                <label htmlFor="online">Online</label>
                            </div>

                            <div className="form-group">
                                <div className={`${styles.labelLeft} mb-1 mt-4`}>Introducir URL</div>
                                <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="username" placeholder="www.example.com" value={form.url} onChange={(event) => {setField("url", event.target.value)}}/>
                            </div>

                            <Button onClick={submit} className={`mt-3 btn text-white rounded-2 px-4 ${styles.submitButton}`}>Cargar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "../../AxiosInstance";
import Header from "../../components/header";
import PasswordInput from "../../components/password_input";
import styles from "../../styles/crud.module.css";

export default function CreateUser() {
    const location = useLocation()

    const [error, setError] = useState(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [birthday, setBirthday] = useState("");
    const [id, setID] = useState("");
    const [docType, setDocType] = useState("");
    const [permissions, setPermissions] = useState("0");

    const [docTypes, setDocTypes] = useState([]);

    useEffect(() => {
        if (location.state) {
            axios.get('user/' + location.state.userID + '/').then((response) => {
                const data = response.data;
                console.log(data);
                setEmail(data.email);
                setName(data.username)
                setPhone(data.numero_telefono);
                setBirthday(data.fecha_de_nacimiento)
                setDocType(data.tipo_documento)
                setID(data.documento_identidad)
                setPermissions((data.is_staff ? "1" : "0"));
            });
        }

        // axios.get('documento_identidad/').then((response) => {
        //     setDocTypes(response.data.results);
        //     setDocType(response.data.results[0].id)
        // });
    }, []);

    const submit = () => {
        if (location.state) {
            const user_id = location.state.userID;
            axios.post('edit_user/', {
                user_id: user_id,
                documento_identidad: id,
                fecha_de_nacimiento: birthday,
                tipo_documento: docType,
                //cod_nacionalidad:
                numero_telefono: phone,
                username: name,
                first_name: name,
                email: email,
                password: password,
                permissions: permissions
            }).then((response) => {
                window.location.replace('/users/list/')
            }).catch((err) => {
                setError(true);
            });
        }
        else {
            axios.post('create_user/', {
                documento_identidad: id,
                fecha_de_nacimiento: birthday,
                tipo_documento: docType,
                //cod_nacionalidad:
                numero_telefono: phone,
                username: name,
                first_name: name,
                email: email,
                password: password,
                permissions: permissions
            }).then((response) => {
                window.location.replace('/users/list/')
            }).catch((err) => {
                setError(true);
            });
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <div className="container-fluid vh-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-6 col-lg-3 rounded p-4 bg-white shadow" style={{ minWidth: '450px', marginTop: '5rem' }}>
                        <div className={styles.crudTitle}>{location.state ? "Editar " : "Crear"} usuario</div>
                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Email</div>
                            <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="username" placeholder="example@gmail.com" value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Nombre y apellido</div>
                            <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="username" placeholder="Nombre y apellido" value={name} onChange={(event) => setName(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Número de teléfono</div>
                            <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="phone" placeholder="999 999 999" value={phone} onChange={(event) => setPhone(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Fecha de nacimiento</div>
                            <input type="date" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="birthday" value={birthday} onChange={(event) => setBirthday(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Tipo de documento</div>
                            <select className={`${styles.borderDarkBlue} w-100`} value={docType} onChange={(event) => setDocType(event.target.value)}>
                                {docTypes.map((type) => (
                                    <option value={type.id} key={type.id}>{type.nombre}</option>
                                ))

                                }
                            </select>
                        </div>

                        <div className="form-group">
                            <div className={`${styles.labelLeft} mb-1 mt-4`}>Documento de Indentidad</div>
                            <input type="text" className={`form-control ${styles.borderDarkBlue} rounded-2`} id="id" value={id} onChange={(event) => setID(event.target.value)} />
                        </div>

                        <PasswordInput password={password} setPassword={setPassword} />

                        <div className={`${styles.labelLeft} mb-1 mt-4`}>Permiso</div>
                        <div className={styles.customRadio}>
                            <input type="radio" id="standard" name="permissions" value="0" checked={permissions === "0"} onChange={(event) => setPermissions(0)} />
                            <label htmlFor="standard">Standard</label>
                            <input type="radio" id="admin" name="permissions" value="1" checked={permissions === "1"} onChange={(event) => setPermissions(1)} />
                            <label htmlFor="admin">Admin</label>
                        </div>

                        {error && <div className={styles.errorLabel}>Por favor revise todos los campos</div>}

                        <Button className={`mt-3 btn text-white rounded-2 px-4 ${styles.submitButton}`} onClick={submit}>{location.state ? "Guardar" : "Crear"}</Button>
                    </div>
                </div>
            </div>
        </div>
    )


}
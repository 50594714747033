import axios from "axios";

const baseUrl = "https://tranqueras-admin.stracontech.com/api/";
//const baseUrl = "http://localhost:8000/api/";

export const baseUrlSockets = 'wss://tranqueras-admin.stracontech.com/ws/central/'
//export const baseUrlSockets = 'ws://localhost:8000/ws/central/'

// let baseUrl = "http://192.168.43.207:8000/api/";
//let baseUrl = "http://localhost:8000/api/"
let refresh = false;

const axiosInstance = axios.create({
    baseURL: baseUrl
});

//TODO: descomentar
// axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

//TODO: descomentar
// axiosInstance.interceptors.response.use(resp => resp, async error => {
//     if (error.response.status === 401 && !refresh) {
//         refresh = true;
//         axiosInstance.post('token/refresh/', {
//             refresh: localStorage.getItem('refresh_token')
//         }).then((response) => {
//             axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
//             localStorage.setItem('access_token', response.data.access);
//             window.location.reload();
//         }).catch((err) => {
//             localStorage.clear();
//             axiosInstance.defaults.headers.common['Authorization'] = null;
//             window.location.href = '/login';
//         });
//     }
//     refresh = false;
//     return error;
// });

export default axiosInstance;
